<template>
    <div class="pr30">
        <div class="ls_flex ls_aic">
            AI标注状态：
            <el-radio-group v-model="params.aiAnnotationState" fill="#31A458" @change="params.pageIndex = 1; getList();">
                <el-radio-button :label="null">全部作品</el-radio-button>
                <el-radio-button :label="1">已标注</el-radio-button>
                <el-radio-button :label="0">未标注</el-radio-button>
                <el-radio-button :label="2">不标注</el-radio-button>
            </el-radio-group>

            <el-input class="mlr20 w300" v-model="params.keyword" placeholder="搜索关键字（作品名称/素材名称）" clearable></el-input>
            <el-button type="primary" @click="params.pageIndex = 1; getList();">
                搜索
            </el-button>
        </div>

        <!-- 列表 -->
        <div class="mt30 ls_flex ls_flex_wrap">
            <div class="wp19 b-solid br8 box-bb ls_p_rel ls_ovh ls_flex_sk mb14 hover-item" v-for="(item, index) in list"
                :key="index">
                <img class="wp100 p12 box-bb" :src="item.coverImage | imgFormat" />

                <div class="fz14 lh22 h22 mb10 tac">作品名称：{{ item.name }}</div>
                <div class="fz14 lh22 h22 mb10 tac">用户名称：{{ item.userName }}</div>
                <div class="fz14 lh22 h22 mb10 tac">作品来源：{{ { 1: "练习", 2: "比赛", 3: "作业" }[item.source] }}</div>
                <div class="fz14 lh22 h22 mb10 tac">
                    编辑器：{{ { 1: "2D编辑器", 2: "3D编辑器", 3: "VR编辑器" }[item.editorType] }}
                </div>
                <div class="fz14 lh22 h22 mb10 tac">
                    ai标注状态：{{ { 0: "未标注", 1: "已标注", 2: "不标注" }[item.aiAnnotationState] }}
                </div>
                <div class="fz14 lh22 h22 mb10 tac">AI描述：{{ item.aiDesc }}</div>
                <div class="fz14 lh22 h22 mb10 tac">AI标签：{{ item.aiLabel && item.aiLabel.join(',') }}</div>
                <div class="c3 fz14 h37 lh37 tac bt-solid ls_flex_between ls_aic plr30">
                    <div class="ls_cp" @click="showLabelDialog(item)">
                        标注
                    </div>

                    <div class="ls_cp" @click="noLabel(item)">
                        不标注
                    </div>
                </div>

                <!-- 浮动悬浮按钮区域 -->
                <div class="ls_p_abs l0 t0 wp100 ls_flex_around ls_aic box-bb bgc08 hover-box">
                    <div>
                        <div class="cf ls_cp pr30 ls_flex  mb10" @click="viewMaterials(item)">
                            <i class="iconfont icon-tiezi mr6" />
                            <span class="fz14">用料</span>
                        </div>
                        <div class="fz14 lh22 h22 tac cf">创建时间：{{ item.createTime }}</div>
                    </div>

                </div>
            </div>
        </div>

        <div class="ls_flex_center mt30">
            <el-pagination :current-page="params.pageIndex" :page-sizes="[10, 15, 20, 25]" :page-size="params.pageSize"
                :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>


        <el-dialog title="ai标注" :visible.sync="extendData.show" width="600px">
            <el-input class="mb20" v-model="extendData.aiDesc" placeholder="AI描述" />
            <el-input type="textarea" rows="8" v-model="extendData.aiTxt" placeholder="AI标签;用’换行\\中文逗号\\英文逗号‘隔开" />

            <span slot="footer" class="dialog-footer">
                <el-button @click="extendData.show = false">取消</el-button>
                <el-button type="primary" @click="confirmLabel">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    data () {
        return {
            extendData: {
                productId: "",
                aiAnnotationState: 1, //0-未标注，1-已标注，2-无需标注
                aiDesc: '',
                aiTxt: '',
                aiLabel: '',
                show: false,
            },

            list: [],
            params: {
                keyword: "",
                aiAnnotationState: null, // AI标注状态 null/0-未标注，1-已标注，2-无需标注
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
        };
    },
    mounted () {
        this.getList();
    },
    methods: {
        getList () {
            this.$ajax
                .post("/admin/product/ai/getList", this.params)
                .then(res => {
                    this.list = res.list || [];
                    this.total = res.total;
                });
        },

        // 查看用料
        viewMaterials (item) {
            let dom = "";
            if (!item.useMaterials) {
                this.$message.warning("暂无用料信息");
                return;
            }

            item.useMaterials.map(item => {
                dom += `<div class="ls_flex ls_aic">
                    <span class="lh26 fz14 cf21 mr10">⬤</span>
                    <span class="lh26 fz14">
                        ${item.materialName} x${item.count}
                    </span>
                </div>`;
            });

            this.$confirm(`<div>${dom}</div>`, "用料详情", {
                showCancelButton: false,
                showConfirmButton: false,
                dangerouslyUseHTMLString: true,
                width: "400px",
            });
        },
        noLabel (item) {
            this.$confirm(`<div>您确认要不标注吗</div>`, "ai标注", {
                dangerouslyUseHTMLString: true,
                width: "400px",
            }).then(res => {
                this.$ajax.post('/admin/product/ai/save', { productId: item.id, aiAnnotationState: 2 }).then(res => {
                    this.$message.success('标记成功')
                    setTimeout(this.getList, 500)
                })
            });
        },
        showLabelDialog (item) {
            this.extendData.productId = item.id;
            this.extendData.aiDesc = item.aiDesc;
            this.extendData.aiTxt = item.aiLabel.join('\n');

            this.extendData.show = true;
        },
        confirmLabel () {
            console.log()
            this.extendData.aiTxt = this.extendData.aiTxt.split('\n').join(',');
            this.extendData.aiTxt = this.extendData.aiTxt.split('，').join(',');
            this.extendData.aiLabel = this.extendData.aiTxt.split(',').map(item => item && item.trim()).filter(item => item);
            this.$ajax.post('/admin/product/ai/save', this.extendData).then(res => {
                this.$message.success('标记成功');
                this.extendData.show = false;
                setTimeout(this.getList, 500)
            })
        },
        handleSizeChange (val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange (val) {
            this.params.pageIndex = val;
            this.getList();
        },
    },
};
</script>
